.FullScreenModalOutBox {
    color: white;
    text-align: center;
    max-width: 25%;
    min-width: 340px;
}

.FullScreenModalInBox {
    background-color: white;
    color: initial;
    border-radius: 5px;
    flex-direction: column;
    display: flex;
    padding: 20px;
}

.FullScreenModalInBox>button {
    margin-top: 20px;
    background-color: #0a395f;
}

.address {
    color: #DF6F17;
    font-weight: bold;
    display: table;
}

.cvs {
    font-size: 50px;
    color: #DF6F17;
    font-weight: bold;
}

.oneline {
    display: inline-block;
}

tbody {
    display: grid;
    grid-template-columns: repeat(2, auto);
}