.ContainerFooter {
  width: 100%;
  height: auto;
  background-color: #0f1111;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: 0;
  position: fixed;
}

.LinksContainerFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LinkFooter {
  text-decoration: none;
  color: #fff;
  font-family: ModernEra;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 25px;
}

.Text {
  width: 100%;
  font-family: "ModernEraMono";
  font-size: 15px;
  color: #fff;
  text-align: center;
}


@media (min-width: 651px) {
  .LinksContainerFooter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .Text {
    font-size: 18px;
  }
}