.BuyMapHeader {
    text-align: center;
    margin: 0;
    font-size: 28px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.ButtonSign {
    margin-right: -12px;
}

div#storepoint-container {
    height:90vh
   }

#storepoint-container #storepoint-search #storepoint-tag-dropdown.storepoint-dropdown {
    display: none !important;
}   