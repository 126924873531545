.ContainerUsers {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 100px;
}

.FilterUserContainer {
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;
    align-items: center;
}

.PaginationContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}

.popup-content {
    margin: auto;
    background: rgb(255, 255, 255);
    width: 50%;
    padding: 5px;
    border: 4px solid hsl(0, 0%, 90%);
    border-radius: 1rem;
}

.popup-arrow {
    color: rgb(255, 255, 255);
}

[role='tooltip'].popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}

[data-popup='tooltip'].popup-overlay {
    background: transparent;
}

form {
    box-sizing: border-box;
    padding: 2rem;
    border-radius: 1rem;
    background-color: hsl(0, 0%, 100%);
    border: 4px solid hsl(0, 0%, 90%);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }