.UserTransactionListContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 15px;
}

.UserTransactionListHeader {
    text-align: center;
    margin: 0 0 20px 0;
    font-size: 28px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.UserTransactionListContainer .MuiFormControl-root .MuiOutlinedInput-root fieldset, label.Mui-focused {
    border-color: white !important;
}

.UserTransactionListContainer label.MuiFormLabel-root, 
.UserTransactionListContainer input, 
.UserTransactionListContainer svg.MuiSvgIcon-root.MuiSelect-icon, 
.UserTransactionListContainer div.MuiSelect-select,
.UserTransactionListContainer .MuiTablePagination-toolbar p,
.UserTransactionListContainer .MuiTablePagination-toolbar button svg {
    color: white !important;
}