.BitcointDetails {
    margin-top: 60px;
    display: grid;
    grid-auto-flow: dense;
}

.ShowTran .BitcointDetails div {
    text-align: left;
    align-items: center;
    margin: 5px 0;
}

.iconAlert {
    color: #DF6F17;
    --fa-primary-color: #ffffff;
}

.iconConfirm {
    color: #ffffff;
    margin-left: 10px;
}

.label {
    grid-column: 1;
    text-align: left;
}

.text {
    grid-column: 2;
    text-align: right;
}

.continue {
    display: grid;
    margin-top: 50px;
	background-color:#f97203;
	border-radius:21px;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:17px;
	padding:11px 100px;
	text-decoration:none;
	text-shadow:0px 0px 0px #2f6627;
}

.svgBitcoin{
    background: url('/src/Assets/images/bitcoin.png') no-repeat;
    background-size: contain;
    display: flex;
    margin-left: 90px;
    width: 150px;
    height: 150px;
}