.BitcointDetails {
    margin-top: 60px;
    display: grid;
    grid-auto-flow: dense;
}

.ShowTran .BitcointDetails div {
    text-align: left;
    align-items: center;
    margin: 5px 0;
}

.iconAlertSuccess {
    color: #01B286;
    --fa-primary-color: #ffffff;
}

.iconConfirm {
    color: #ffffff;
    margin-left: 10px;
}

.continue {
    display: grid;
    margin-top: 50px;
	background-color:#f97203;
	border-radius:21px;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:17px;
	padding:11px 100px;
	text-decoration:none;
	text-shadow:0px 0px 0px #2f6627;
}

.bitcoinInfo{
    margin-top: 20px;
    font-weight: bold;
}

.walletInfo{
    margin-top: 20px;
    font-weight: bold;
}