.nameContainer {
    display: inline-block;
}

input[type=file]::file-selector-button {
    border: none;
    background: #084cdf;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
    font-size:28px;
    position:absolute;
    margin-left: 100px;
  }
  
  input[type=file]::file-selector-button:hover {
    background: #0d45a5;
  }
  

  .drop-container {
    position: relative;
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    border-radius: 10px;
    cursor: pointer;
    transition: background .2s ease-in-out, border .2s ease-in-out;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .drop-container:hover {
    background: #eee;
    border-color: #111;
  }
  
  .drop-container:hover .drop-title {
    color: #222;
  }
  
  .drop-title {
    color: #444;
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    transition: color .2s ease-in-out;
  }
  