.FormBuy div p.Calculating{
    align-items: center;
    justify-content: space-around;
    font-size: 15px;
}

.FormBuy div ul.Points{
    text-align: left;
    font-size: 15px;
}

.footText {
    display:inline;
    font-size: .8rem;
    text-align: center;
    line-height: 2px;
}

.buttonlink {
    background-color:#0a0a23;
    color: #fff;
    border:none; 
    border-radius:10px; 
    padding: 15px 20px;
    display: block;
  }
