body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.CancelLink {
  font-weight: bold;
  color: #0a395f;
  margin-top: 10px;
  cursor: pointer;
}

div[data-amplify-authenticator] {
  display:flex;
  justify-content: center;
  height: 100vh;
}

form[data-amplify-form] > div  {
  width: 400px;
}

form[data-amplify-form] > div > fieldset  {
  width: 400px;
}

form[data-amplify-form] > fieldset  {
  width: 400px;
}