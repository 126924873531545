.FullScreen {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0a395f;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.MessageYellow {
    color: #e37128;
}

.LoadingTable {
    align-self: center;
    color: #e37128;
}