.FormValidate .TryAgain {
    font-weight: bold;
    color: #0a395f;
}

.countdown-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem 0;
  }