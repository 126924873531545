.FullScreenModalOutBox {
    color:  white;
    text-align: center;
    max-width: 25%;
    min-width: 340px;
}

.FullScreenModalInBox {
    background-color: white;
    color: initial;
    border-radius: 5px;
    flex-direction: column;
    display: flex;
    padding: 20px;
}

.FullScreenModalInBox > button {
    margin-top: 20px;
}

.address{
    color: #DF6F17;
}