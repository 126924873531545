.BarcodePollingNotification {
    display: flex;
    padding: 10px;
    align-self: center;
    justify-content: space-between;
    width: 75%;
    border-radius: 5px;
    font-weight: bold;
    color: gray;
    background: transparent;
    align-items: center;
}

.BarcodePollingNotification .Orange {
    color: #e37128;
}
.BarcodePollingNotification svg {
    width: auto !important;
}