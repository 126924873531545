.InstructionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.InstructionContainer .InstructionH2First {
    margin-top: 0px;
    margin-left: 25px;
    margin-bottom: 0px;
}
.InstructionContainer .InstructionH2Second {
    margin-top: 0px;
}
.InstructionContainer img, .InstructionContainer h3 {
    width: 80%;
    max-width: 400px;
}
.InstructionContainer h2 {
    font-size: 28px;
    text-align: center;
}
.InstructionContainer h3 {
    font-size: 26px;
    text-align: center;
}
.InstructionContainer p {
    font-size: 16px;
    text-align: center;
    width: 90%;
    max-width: 400px;
    margin-top: 0;
    margin-bottom: 80px;
}