.WVHeaderCard {
    background-color: var(--amplify-colors-yellow-10);
}

.WVShellContainer {
    position: relative;
    min-height: 100vh;
    background-color: #0a395f;
    color: white;
  } 

.WVWrapper {
    display: flex;
    align-self: center;
    flex-direction: column;
    max-width: 800px;
    margin: auto;
    width: 100%;
}

.WVChildrenContainer {
    display: flex;
    overflow-x: hidden;
    justify-content: center;
}
.DrawerButton {
    z-index: 1;
    position: absolute !important;
    left: 15px;
    top: 0px;
}