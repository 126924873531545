.ContainerTransactions {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.FilterTransContainer {
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.SearchContainer {
    display: flex;
    margin-bottom: 30px;
    justify-content: center;
}

@media only screen and (min-width: 768px) {
    .SearchContainer {
        justify-content: flex-end;
    }
}