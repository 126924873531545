.CodeSmsInputContent {
    display: flex;
    align-items: center;
    /* align-content: center; */
    justify-content: center;
}

.CodeSmsInputDisplay {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    width: 32px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    position: relative;
    color: #0a395f;
}
.CodeSmsInputDisplay:last-child {
    border-right: none;
}
  
.CodeSmsInputWrap {
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: inline-block;
    position: relative;
    display: flex;
}
.CodeSmsInputWrapHiddenInput {
    position: absolute;
    border: none;
    font-size: 32px;
    text-align: center;
    background-color: transparent;
    outline: none;
}
.CodeSmsInputShadows {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    box-shadow: 0 0 0 1px #e37128;
}