.FilterContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.TransactionItem{
    margin-bottom: 30px;
}

.TransactionItem.label-background-blue label.MuiFormLabel-root{
    background-color: #0a395f;
    padding: 0 7px;
}

.TransactionItem.label-background-white label.MuiFormLabel-root{
    background-color: #ffffff;
    padding: 0 7px;
}

@media only screen and (min-width: 768px) {
    .FilterContainer {
        display: flex;
        flex-direction: row;
    }
}