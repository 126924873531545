.ShowBarcode svg {
    width: 100%;
}

.ShowBarcode .BarcodeDetails {
    display: flex;
    flex-direction: column;
}

.ShowBarcode .BarcodeDetails div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    align-items: center;
    margin: 5px 0;
}

.countdown-text {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 1rem;
    margin-top: -10px;
}

.cashLimit {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    color: #DF6F17;
    margin-top: -60px;
    margin-left: 20px;
}

.alert {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 1rem;
    margin-top: -10px;
}

.iconAlert {
    color: #DF6F17;
}

.footText {
    display:inline;
    font-size: .8rem;
    text-align: center;
    line-height: 2px;
}

.footTextNomal {
    display:inline;
    font-size: .8rem;
    text-align: center;
}

.stack{
    align-items: center;
    justify-content: space-around;
    font-size: 15px;
    padding-top: 10px;
    margin: 0;
}