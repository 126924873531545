.ContainerHeader {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    background-color: var(--amplify-colors-yellow-10);
}

.HeaderLeft {
    display: flex;
    align-items: center;
}

.HeaderRight {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.amplify-button:focus {
    background-color: var(--amplify-colors-font-disabled);
    border-color: transparent;
    box-shadow: none;
}

.amplify-icon {
    border-color: transparent;
    line-height: 0;
}

.amplify-menu {
    background-color: var(--amplify-colors-background-secondary);
}
