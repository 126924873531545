.HeaderCard {
    background-color: var(--amplify-colors-yellow-10);
}

.ShellContainer {
    position: relative;
    min-height: 100vh;
}

.Wrapper {
    display: flex;
    align-self: center;
    width: 90%;
    min-width: 400px;
    margin: auto;
}

.ChildrenContainer {
    display: flex;
    padding: 100px 0 65px 0;
    overflow-x: hidden;
    min-height: -webkit-fill-available;
    justify-content: center;
}
